import { Datagrid, DateField, RaRecord, TextField, useRefresh } from "react-admin";
import CoreEditButton from "../../../../shared/components/core/CoreEditButton";
import CoreShowButton from "../../../../shared/components/core/CoreShowButton";
import sacopModules from "../../../../shared/utilities/sacopModules";
import CustomButton from "../../../../shared/components/core/CustomButton";
import SendIcon from '@mui/icons-material/Send';
import { Sacop } from "../../../../shared/types/types";
import fetchs from "../../../../shared/utilities/fetchs";

interface MobileGridProps {
    data? : RaRecord[]; 
}

const MobileGrid = (props: MobileGridProps) => {
    const refresh = useRefresh();

    const handleButtonClick = (record: Sacop) => {
        const { sendSacopToResponsibleForImplementation } = fetchs();

        sendSacopToResponsibleForImplementation(record.id)
            .then((data) => {
                refresh();
            });
    }; 

    return (
        <Datagrid bulkActionButtons={false}>
            <TextField source="id" label="resources.sacop.fields.id"  variant="body1" />
            <TextField source="motive.motiveName" label="resources.sacop.fields.motive.motiveName" variant="body1" sortable={false} />
            <DateField source="dateRequestAction" label="resources.sacop.fields.dateRequestAction" variant="body1" />
            <DateField source="expirationDateAction" label="resources.sacop.fields.expirationDateAction" variant="body1" />
            <TextField source="closingStatus.statusName" label="resources.sacop.fields.actionStatus" variant="body1" sortable={false}/>
            <TextField source="verificationStatus.statusName" label="resources.sacop.fields.verificationStatus" variant="body1" sortable={false}/>
            
            <CoreEditButton permission="BACKOFFICE_ANALYSIS_OF_SHARES_U" actionModule={sacopModules.ANALYSISFORSHARES} /> 
            <CoreShowButton permission="BACKOFFICE_ANALYSIS_OF_SHARES_L" />
            <CustomButton  title="Enviar" icon={<SendIcon />} onClick={handleButtonClick}  action={sacopModules.ANALYSISFORSHARES} />
        </Datagrid>
    );
}

MobileGrid.defaultProps = {
    data : {},
    ids : [],
};

export default MobileGrid;