import {  BooleanField, Datagrid, EmailField, Loading, RaRecord, TextField, useListContext, useTranslate } from "react-admin";
import { User } from "../../../../shared/types/types";
import CoreEditButton from "../../../../shared/components/core/CoreEditButton";
import sacopModules from "../../../../shared/utilities/sacopModules";
import NoDataFound from "../../../../shared/components/noDataFound/NoDataFound";

interface MobileGridProps {
    data?: RaRecord[];
}

const MobileGrid = (props: MobileGridProps) => {

    const { data, isLoading } = useListContext<User>();
    const translate = useTranslate();
    if (isLoading || data.length === 0) 
    {
        if (data?.length === 0)
            return <NoDataFound />
        return <Loading />; 
    }

    return (
        <Datagrid bulkActionButtons={false}>
            <TextField source="name" variant="body1"/>
            <TextField source="lastname" variant="body1"/>
            <EmailField source="mail" />
            <TextField source="phone" />
            <TextField source="rol.name" sortable={false} />
            <BooleanField source="active" />
            
            <CoreEditButton permission="BACKOFFICE_USER_U" actionModule={sacopModules.NONE} />
        </Datagrid>

    );
};

MobileGrid.defaultProps = {
    data: {},
    ids: [],
};

export default MobileGrid;
