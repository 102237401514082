import {  Theme, useMediaQuery } from "@mui/material";
import { List, SearchInput, useListContext, TopToolbar, FilterButton, ExportButton, SortButton, TextInput, BooleanInput } from "react-admin";
import MobileGrid from "./MobileGrid";
import CoreCreateButton from "../../../../shared/components/core/CoreCreateButton";

const ListActions = () => (
    <TopToolbar>
        <CoreCreateButton permission="BACKOFFICE_USER_C" />
        <SortButton fields={['name']} />
        <FilterButton />
        <ExportButton />
    </TopToolbar>
);

const userFilters = [
    <SearchInput source="q" alwaysOn />,
    <BooleanInput source="active" />,
    <TextInput source="name" />
];

const TabbedDatagrid = () => {
    const listContext = useListContext();
    const isXSmall = useMediaQuery<Theme>(theme =>
        theme.breakpoints.down('sm')
    );
    return (
        <MobileGrid  {...listContext} />
    );
}

const UserList = () => {
    return (<List
        filterDefaultValues={ { active: true }}
        sort={{ field: 'name', order: 'DESC' }}
        perPage={25}
        filters={userFilters}
        actions={<ListActions />}
    >
        <TabbedDatagrid />
    </List>);
};

export default UserList;
