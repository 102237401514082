import { required, NumberInput, TextInput } from "react-admin";

import Grid from "@mui/material/Unstable_Grid2/Grid2";

export default function VerificationInputGroup() {
  return (
    <>
      <Grid xs={12} md={6}>
        <TextInput
          source="calibration.verificationPeriod"
          label="resources.instrument.fields.calibration.verification_period"
          fullWidth
          validate={required()}
        />
      </Grid>
    </>
  );
}
