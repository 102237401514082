import { DateInput, FileField, FileInput, required, TextInput } from "react-admin";
import Grid from "@mui/material/Unstable_Grid2/Grid2";
import { Typography } from "@mui/material";

export default function QualityReportForm() {
  return (
    <Grid sx={{ width: "100%" }} container lg={10} lgOffset={1} spacing={2}>
      <Grid xs={12}>
        <Typography variant="h6">INFORME DE CALIDAD</Typography>
      </Grid>
      <Grid container xs={12} md={6} rowSpacing={2}>
        <Grid xs={12}>
          <TextInput
            // source="operationRegistry.report.number"
            source="qualityReport.number"
            disabled
            helperText={false}
            label="resources.laboratory_registry.fields.quality_report.fields.number"
          />
        </Grid>
        <Grid xs={12}>
          <DateInput
            source="qualityReport.date"
            helperText={false}
            label="resources.laboratory_registry.fields.quality_report.fields.date"
          />
        </Grid>
      </Grid>
      <Grid xs={12} md={6}>
        <Grid xs={12} md={4}>
          <FileInput
            source="qualityReport.file"
            helperText={false}
            label="resources.laboratory_registry.fields.quality_report.fields.file"
          >
            <FileField source="src" title="title" target="_blank" />
          </FileInput>
        </Grid>
      </Grid>
    </Grid>
  );
}
