import { DateInput, FileField, FileInput, required, SelectInput, TextInput } from "react-admin";

import periods from "./data/periods";
import Grid from "@mui/material/Unstable_Grid2/Grid2";

export default function CalibrationInputGroup() {
  return (
    <>
      <Grid xs={12} md={6}>
        <DateInput
          source="calibration.date"
          label="resources.instrument.fields.calibration.date"
          fullWidth
          validate={required()}
        />
      </Grid>
      <Grid xs={12} md={6}>
        <SelectInput
          choices={periods}
          source="calibration.calibrationPeriod"
          label="resources.instrument.fields.calibration.calibration_period"
          fullWidth
          validate={required()}
        />
      </Grid>

      <Grid xs={12} md={6}>
        <TextInput
          source="calibration.calibratedBy"
          label="resources.instrument.fields.calibration.calibrated_by"
          fullWidth
          validate={required()}
        />
      </Grid>
      <Grid xs={12} md={6}>
        <TextInput
          source="calibration.responsible"
          label="resources.instrument.fields.calibration.responsible"
          fullWidth
          validate={required()}
        />
      </Grid>

      <Grid xs={12} md={6}>
        <FileInput
          source="calibration.certcalibration"
          label="resources.instrument.fields.calibration.certificate"
        >
          <FileField source="src" title="title" target="_blank" />
        </FileInput>
      </Grid>

    </>
  );
}
