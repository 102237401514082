import { BooleanField, ChipField, Datagrid, DateField, Loading, RaRecord, ReferenceArrayField, SelectField, SingleFieldList, TextField, useListContext } from "react-admin";
import CoreEditButton from "../../../../shared/components/core/CoreEditButton";
import CoreDeleteButton from "../../../../shared/components/core/CoreDeleteButton";
import { Instrument } from "../../../../shared/types/types";
import sacopModules from "../../../../shared/utilities/sacopModules";
import NoDataFound from "../../../../shared/components/noDataFound/NoDataFound";
import periods from "./data/periods";


interface MobileGridProps {
    data?: RaRecord[];
}

const MobileGrid = (props: MobileGridProps) => {
    const { data, isLoading } = useListContext<Instrument>();

    if (isLoading || data.length === 0) {
        if (data?.length === 0)
            return <NoDataFound />
        return <Loading />;
    }

    return (
        <Datagrid bulkActionButtons={false}
        >
            <TextField label="resources.instrument.fields.name" source="name" variant="body1" />
            <TextField label="resources.instrument.fields.code" source="code" variant="body1" />
            <TextField label="resources.instrument.fields.brand" source="brand" variant="body1" />
            <TextField label="resources.instrument.fields.model" source="model" variant="body1" />
            <SelectField 
                label="resources.instrument.fields.calibration.calibration_period" 
                source="calibration.calibrationPeriod"
                choices={periods}
            />

            <DateField label="resources.instrument.fields.calibration.date" source="calibration.date" variant="body1" />

            <ReferenceArrayField label="resources.instrument.fields.costCenter" source="costCenterId" reference="localization">
                <SingleFieldList>
                    <ChipField source="name" />
                </SingleFieldList>
            </ReferenceArrayField>

            <BooleanField label="resources.instrument.fields.active" source="active" variant="body1" />

            <CoreEditButton permission="BACKOFFICE_INSTRUMENT_U" actionModule={sacopModules.NONE} />
            <CoreDeleteButton permission="BACKOFFICE_INSTRUMENT_D" />
        </Datagrid>
    );
};

MobileGrid.defaultProps = {
    data: {},
    ids: [],
};

export default MobileGrid;
