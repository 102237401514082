import { Box, Chip, Divider, InputAdornment, InputLabel, Stack, Typography } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2/Grid2";
import {
    TextInput,
    required,
    SelectInput,
    SimpleForm,
    DateInput,
    Loading,
    FileInput,
    FileField,
    BooleanInput,
    useRecordContext,
    NumberInput,
} from "react-admin";
import FormToolbar from "../../../../shared/components/toolbar/FormToolbar";
import { ChangeEvent, ReactElement, useEffect, useState } from "react";
import { Sacop, SacopStatus, User } from "../../../../shared/types/types";
import fetchDataService from "../../../../shared/utilities/fetchs";
import { typeOfGeneratingEntityOptions } from "../requestForAction/data/typeOfGeneratingEntityOptions";


interface VerificationEffectivenessFormProps {
    edit?: boolean;
    formTitle: ReactElement;
    firstSection : ReactElement;
    secondSection : ReactElement;
    thirdSection : ReactElement;
    fourthSection : ReactElement;
    fifthSection : ReactElement;
    labelEvidence : ReactElement;
    labelImpact : ReactElement;
    labelWorkTeamMembers: ReactElement;
}

const MasterOFSharesForm = ({ 
    edit, 
    formTitle,
    firstSection, 
    secondSection,
    thirdSection,
    fourthSection,
    fifthSection,
    labelEvidence,
    labelImpact,
    labelWorkTeamMembers
}: VerificationEffectivenessFormProps) => {
    const [loading, setLoading] = useState(true);
    const [sacopStatusForClosingByMaster, setSacopStatusForClosingByMaster] = useState<SacopStatus[]>();
    const [sacopStatusVerificationByMaster, setSacopStatusVerificationByMaster] = useState<SacopStatus[]>();
    const [members, setMembers] = useState<User[]>();
    const [additionalFieldsToShow, setAdditionalFieldsToShow] = useState({
        isArea: false,
        isAuthority: false,
        isCustomer: false,
        isOthers: false
    });
    const record = useRecordContext<Sacop>();

    const loadAdditionalFieldsToShow = (typeOfGeneratingEntityId: number) => {
        setAdditionalFieldsToShow({
            isArea: typeOfGeneratingEntityId === typeOfGeneratingEntityOptions["area"],
            isAuthority: typeOfGeneratingEntityId === typeOfGeneratingEntityOptions["authority"],
            isCustomer: typeOfGeneratingEntityId === typeOfGeneratingEntityOptions["customer"],
            isOthers: typeOfGeneratingEntityId === typeOfGeneratingEntityOptions["others"]
        });
    } 

    useEffect(() => {
        const { getSacopStatusForClosingByMasterData, getSacopStatusForVerificationByMasterData} = fetchDataService();
        Promise.all([
            getSacopStatusForClosingByMasterData(),
            getSacopStatusForVerificationByMasterData()
        ]).then(([sacopStatusForClosingByMasterResponse,
            sacopStatusForVerificationByMasterResponse]) => {
            setSacopStatusForClosingByMaster(sacopStatusForClosingByMasterResponse.data)
            setSacopStatusVerificationByMaster(sacopStatusForVerificationByMasterResponse.data);
            setLoading(false);
        }).catch(error => {
            console.error('Error fetching data in Implementation:', error);
            setLoading(false);
        });
    }, []);

    useEffect(() => {
        loadAdditionalFieldsToShow(record?.typeOfGeneratingEntityId);
        setMembers(record?.members);
    }, [record]);

    if (loading) {
        return <Loading />;
    }
        
    return (
        <SimpleForm
            toolbar={<FormToolbar edit={edit ? true : undefined} />}
            sx={{ mt: 3, mb: 3 }}
        >
            <Grid container lg={10} lgOffset={1} columnSpacing={2}>
                <Grid xs={12} sx={{ mb: 2 }}>
                    <Typography variant="h6">{formTitle}</Typography>
                </Grid>
    
                    {/* Sección 1 */}
                    <Grid container md={12} sx={{ mb: 3 }}>
                        <Grid xs={12}>
                            <Typography variant="subtitle1">{firstSection}</Typography>
                            <Divider />
                        </Grid>
                        <Grid container md={12}>
                        <Grid xs={12} md={4}>
                            <TextInput
                                source="id"
                                label="resources.sacop.fields.id"
                                fullWidth
                                disabled
                            />
                        </Grid>
                        <Grid xs={12} md={4}>
                            <DateInput 
                            source="dateRequestAction"
                            label="resources.sacop.fields.dateRequestAction"
                            variant="outlined"
                            fullWidth
                            disabled
                        />
                        </Grid>
                        <Grid xs={12} md={4}>
                            <TextInput
                                source="generatingEntity"
                                label="resources.sacop.fields.generatingEntity"
                                fullWidth
                                disabled
                            />
                        </Grid>
                        <Grid xs={12} md={4}>
                            <TextInput
                                source="typeOfGeneratingEntity.nameOfGeneratingEntity"
                                label="resources.sacop.fields.typeOfGeneratingEntityId"
                                fullWidth
                                disabled
                            />
                        </Grid>
                        {additionalFieldsToShow.isAuthority && (
                            <Grid xs={12} md={4}>
                                <TextInput
                                    source="authority.authorityName"
                                    label="resources.sacop.fields.authorityId"
                                    fullWidth
                                    disabled
                                />
                            </Grid>
                        )}
                        {additionalFieldsToShow.isArea && (
                            <Grid xs={12} md={4}>
                                <TextInput
                                    source="localization.name"
                                    label="resources.sacop.fields.localizationId"
                                    fullWidth
                                    disabled
                                />
                            </Grid>
                        )}
                        {additionalFieldsToShow.isCustomer && (
                            <Grid xs={12} md={4}>
                                <TextInput
                                    source="exporter.name"
                                    label="resources.sacop.fields.exporterId"
                                    fullWidth
                                    disabled
                                />
                            </Grid>
                        )}
                        {additionalFieldsToShow.isOthers && (
                            <Grid xs={12} md={4}>
                                <TextInput
                                    source="freeText"
                                    label="resources.sacop.fields.freeText"
                                    fullWidth
                                    disabled
                                />
                            </Grid>
                        )}
                        <Grid xs={12} md={4}>
                            <TextInput
                                source="regulation.regulationName"
                                label="resources.sacop.fields.regulationId"
                                fullWidth
                                disabled
                            />
                        </Grid>
                        <Grid xs={12} md={4}>
                            <TextInput
                                source="motive.motiveName"
                                label="resources.sacop.fields.motiveId"
                                fullWidth
                                disabled
                            />
                        </Grid>
                        <Grid xs={12} md={4}>
                            <TextInput
                                source="whoReports.fullName"
                                label="resources.sacop.fields.whoReports"
                                fullWidth
                                disabled
                            />
                        </Grid>
                        <Grid xs={12} md={4}>
                            <TextInput
                                source="responsibleForAnalysis.fullName"
                                label="resources.sacop.fields.responsibleForAnalysisId"
                                fullWidth
                                disabled
                            />
                        </Grid>
                        <Grid xs={12} md={12}>
                            <TextInput
                                source="problemDescription"
                                label="resources.sacop.fields.problemDescription"
                                fullWidth
                                multiline
                                disabled
                            />
                        </Grid>
                        <Grid xs={12} md={12}>
                            <TextInput
                                source="immediateActions"
                                label="resources.sacop.fields.immediateActions"
                                fullWidth
                                multiline
                                disabled
                            />
                        </Grid>
                        <Grid xs={12} md={12}>
                            <TextInput
                                source="textualEvidence"
                                label="resources.sacop.fields.textualEvidence"
                                fullWidth
                                multiline 
                                disabled
                                />
                        </Grid>
                        {record?.evidenceOfRequest && (
                            <Grid xs={12} md={6} sx={{ pl: 3, mb: 3 }}>
                                <Grid container direction="column" spacing={1}>
                                    <InputLabel>
                                        <Typography variant="subtitle2" sx={{ fontSize: '0.875rem' }}>{labelEvidence}</Typography>
                                    </InputLabel>
                                    <Grid>
                                        <FileField source="evidenceOfRequest.src" title="evidenceOfRequest.title" target="_blank" />
                                    </Grid>
                                </Grid>
                            </Grid>
                        )}
                    </Grid>
    
                    {/* Sección 2 */}
                    <Grid width="100%">
                        <Stack spacing={2}>
                            <Typography variant="subtitle1">{secondSection}</Typography>
                            <Divider />
                        </Stack>
                        <TextInput
                            source="visibleCause"
                            label="resources.sacop.fields.visibleCause"
                            fullWidth
                            multiline
                            disabled
                        />
                        <TextInput
                            source="causeOrigin"
                            label="resources.sacop.fields.causeOrigin"
                            fullWidth
                            multiline
                            disabled
                        />
                        <TextInput
                            source="rootCause"
                            label="resources.sacop.fields.rootCause"
                            fullWidth
                            multiline
                            disabled
                        />
                        <TextInput
                            source="correctiveActions"
                            label="resources.sacop.fields.correctiveActions"
                            fullWidth
                            multiline
                            disabled
                        />
                        <Stack mb={2}>
                            <Typography variant="subtitle2" sx={{ fontSize: '0.875rem' }}>{labelImpact}</Typography>
                        </Stack>
                        <Stack>
                            <Stack direction="row" spacing={2}>
                                <NumberInput 
                                    source="impact.c"
                                    label="C"
                                    fullWidth
                                    disabled
                                />
                                <NumberInput 
                                    source="impact.a"
                                    label="A"
                                    fullWidth
                                    disabled
                                />
                                <NumberInput
                                    source="impact.ca"
                                    label="CA"
                                    fullWidth
                                    disabled
                                />
                            </Stack>
                            <Stack direction="row" spacing={2}>
                                <NumberInput 
                                    source="impact.s"
                                    label="S"
                                    fullWidth
                                    disabled
                                />
                                <NumberInput 
                                    source="impact.so"
                                    label="SO"
                                    fullWidth
                                    disabled
                                />
                                <NumberInput
                                    source="impact.sso"
                                    label="SSO"
                                    fullWidth
                                    disabled
                                />
                            </Stack>
                        </Stack>
                        <Stack direction="row" spacing={2}>
                            <TextInput
                                source="valorization.lossValuationName"
                                label="resources.sacop.fields.valorizationId"
                                fullWidth
                                disabled
                            />
                            <NumberInput
                                source="valuationAmount"
                                label="resources.sacop.fields.valuationAmount"
                                fullWidth
                                disabled
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">$</InputAdornment>
                                    ),
                                }}
                            />
                            <DateInput 
                                source="dateOfFormationOfWorkTeam"
                                label="resources.sacop.fields.dateOfFormationOfWorkTeam"
                                fullWidth
                                disabled
                            />
                        </Stack>
                        {members /*&& members.length > 0*/ && (
                            <Box sx={{ mb: 2 }}>
                                <Typography variant="subtitle2" sx={{ fontSize: '0.875rem', mb: 1 }}>
                                    {labelWorkTeamMembers}
                                </Typography>
                                <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1 }}>
                                    {members?.map(member => (
                                        <Chip key={member.id} label={member.fullName} />
                                    ))}
                                </Box>
                            </Box>
                        )}
                        <TextInput
                            source="evaluationResult"
                            label="resources.sacop.fields.evaluationResult"
                            fullWidth
                            multiline
                            disabled
                        />
                    </Grid>

                {/* Sección 3 */}
                <Grid container md={12} sx={{ mb: 3 }}>
                    <Grid xs={12}>
                        <Typography variant="subtitle1">{thirdSection}</Typography>
                        <Divider />
                    </Grid>
                    <Grid xs={12} md={4}>
                        <TextInput 
                            source="responsibleForImplementation.fullName"
                            label="resources.sacop.fields.responsibleForImplementationId"
                            variant="outlined"
                            fullWidth
                            disabled
                        />
                    </Grid>
                    <Grid xs={12} md={4}>
                        <DateInput 
                            source="implementationDate"
                            label="resources.sacop.fields.implementationDate"
                            variant="outlined"
                            fullWidth
                            disabled
                        />
                    </Grid>
                    {record?.evidence && (
                        <Grid xs={12} md={6} sx={{ pl: 3, mb: 3 }}>
                            <Grid container direction="column" spacing={1}>
                                <InputLabel>
                                    <Typography variant="subtitle2" sx={{ fontSize: '0.875rem' }}>{labelEvidence}</Typography>
                                </InputLabel>
                                <Grid>
                                    <FileField source="evidence.src" title="evidence.title" target="_blank" />
                                </Grid>
                            </Grid>
                        </Grid>
                    )}
                </Grid>
    
                {/* Sección 4 */}
                <Grid container md={12} sx={{ mb: 3 }}>
                    <Grid xs={12}>
                        <Typography variant="subtitle1">{fourthSection}</Typography>
                        <Divider />
                    </Grid>
                    <Grid xs={12} md={4}>
                        <TextInput 
                            source="responsibleForClosing.fullName"
                            label="resources.sacop.fields.responsibleForClosingId"
                            variant="outlined"
                            fullWidth
                            disabled
                        />
                    </Grid>
                    <Grid xs={12} md={4}>
                        <DateInput 
                            source="closeDate"
                            label="resources.sacop.fields.closeDate"
                            variant="outlined"
                            fullWidth
                            disabled
                        />
                    </Grid>
                    <Grid xs={12} md={4}>
                        <SelectInput 
                            source="closingStatusId"
                            label="resources.sacop.fields.closingStatusId"
                            choices={sacopStatusForClosingByMaster?.map(item => ({ id: item.id, name: item.statusName }))}
                            fullWidth
                        />
                    </Grid>
                    <Grid xs={12} md={12}>
                        <TextInput
                            source="closingRemark"
                            label="resources.sacop.fields.closingRemark"
                            fullWidth
                            multiline
                            disabled
                        />
                    </Grid>
                </Grid>
    
                {/* Sección 5 */}
                <Grid container md={12} sx={{ mb: 3 }}>
                    <Grid xs={12}>
                        <Typography variant="subtitle1">{fifthSection}</Typography>
                        <Divider />
                    </Grid>
                    <Grid xs={12} md={4}>
                        <TextInput 
                            source="responsibleForVerification.fullName"
                            label="resources.sacop.fields.responsibleForVerificationId"
                            variant="outlined"
                            fullWidth
                            disabled
                        />
                    </Grid>
                    <Grid xs={12} md={4}>
                        <DateInput 
                            source="verificationDate"
                            label="resources.sacop.fields.verificationDate"
                            variant="outlined"
                            fullWidth
                            disabled
                        />
                    </Grid>
                    <Grid xs={12} md={4}>
                        <SelectInput 
                            source="verificationStatusId"
                            label="resources.sacop.fields.verificationStatusId"
                            choices={sacopStatusVerificationByMaster?.map(item => ({ id: item.id, name: item.statusName }))}
                            fullWidth
                        />
                    </Grid>
                    <Grid xs={12} md={12}>
                        <TextInput
                            source="verificationObservations"
                            label="resources.sacop.fields.verificationObservations"
                            fullWidth
                            multiline
                            disabled
                        />
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </SimpleForm>
    );
    
};

export default MasterOFSharesForm;