import { Edit, useRecordContext, useTranslate } from "react-admin";
import RequestForActionForm from "./RequestForActionForm";
import { Sacop } from "../../../../shared/types/types";

export default function RequestForActionEdit() {
  return(
      <Edit actions={false} title={<RequestForActionTitle />}>
          <RequestForActionForm formTitle={<ActionEditTitle />} edit={true}/>
      </Edit>
  );
}

const RequestForActionTitle = () => {
    const sacopRecord = useRecordContext<Sacop>();
    const translate = useTranslate();
    return sacopRecord ? (
        <span>
            {`${translate("resources.sacop.titles.requestForAction.title")}`}
            {/* {`${translate("resources.sacop.titles.requestForAction.title")} ${truncate(sacopRecord.name)}`} */}
        </span>
    ) : null;
};

const ActionEditTitle = () => {
    const translate = useTranslate();
    return (
        <span>
            {`${translate("resources.sacop.actions.requestForAction.edit.title")}`}
        </span>
    );
};