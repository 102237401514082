import { Typography } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2/Grid2";
import {
  TabbedForm,
  TextInput,
  required,
  SelectInput,
  FileInput,
  FileField,
  SimpleForm,
  BooleanInput,
} from "react-admin";
import FormToolbar from "../../../../shared/components/toolbar/FormToolbar";

interface LaboratoryFormProps {
  edit?: boolean;
  formTitle: string;
}
const LaboratoryForm = ({ edit, formTitle }: LaboratoryFormProps) => {
  return (
    <SimpleForm
      toolbar={<FormToolbar edit={edit ? true : undefined} />}
      sx={{ mt: 3, mb: 3 }}
      defaultValues={{ id: "0000-0000-0000-0000" }}
    >
      <Grid container lg={10} lgOffset={1} columnSpacing={2}>
        <Grid xs={12} sx={{ mb: 2 }}>
          <Typography variant="h6">{formTitle.toUpperCase()}</Typography>
        </Grid>
        <Grid container md={6}>
          <Grid xs={12} md={6}>
            <TextInput
              source="name"
              label="resources.laboratory.fields.name"
              fullWidth
              validate={required()}
            />
          </Grid>
          <Grid xs={12} md={6}>
            <SelectInput
              source="type"
              label="resources.laboratory.fields.type.name"
              fullWidth
              validate={required()}
              choices={[
                { id: "Interno", name: "Interno" },
                { id: "Subcontratado", name: "Subcontratado" },
              ]}
            />
          </Grid>
          <Grid xs={12}>
            <TextInput
              source="currentResolution"
              label="resources.laboratory.fields.current_resolution"
              fullWidth
              //validate={required()}
            />
          </Grid>
          <Grid xs={12} md={6}>
              <BooleanInput
                source="active"
                helperText={false}
                label="resources.laboratory.fields.active"
              />
            </Grid>
        </Grid>
        <Grid
          xs={12}
          md={6}
          sx={{
            pl: 3,
          }}
        >
          <FileInput
            source="accreditationFile"
            label="resources.laboratory.fields.accreditation_file"
          >
            <FileField source="src" title="title" target="_blank" />
          </FileInput>
        </Grid>
      </Grid>
    </SimpleForm>
  );
};

export default LaboratoryForm;
