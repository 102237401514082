import { Refresh } from "@mui/icons-material";
import { Button, Tooltip } from "@mui/material";
import fetchDataService from "../../../../shared/utilities/fetchs";
import { useNotify } from "react-admin";

export default function PasswordGenerator({ email }) {
  const notify = useNotify();
  const vText = `\"AHK\" + 4 últimos digitos del RUT + @. Ejemplo:
  AHK9701@`;
  const handleClick = async (e) => {
    const { putRegeneratePassword } = fetchDataService();
    await putRegeneratePassword(email);
    notify("Contraseña regenerada", {
      type: "info",
    });
  }

  return (
    <Tooltip title={vText}>
      <Button
        onClick={handleClick}
        startIcon={<Refresh />}
        size="small"
        variant="outlined"
        style={{ textTransform: "none" }}
      >
        Regenerar contraseña
      </Button>
    </Tooltip>
  );
}
